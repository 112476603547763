<script setup>
    import Delete from 'o365.vue.components.Action.Delete.vue';
    import { defineProps} from "vue";
    const props = defineProps({
        item: null,
        openOrApply: Function

    });

    const removeFilterPermission = (item) =>{

    }
</script>

<template>

    <div class="d-flex hover-wrapper" >
        <button type="button"
            class="btn btn-link p-0 text-decoration-none text-truncate"
            :class="{ 'fw-bold': item.current }"
            :title="$t(item.Name ?? item.FilterName)"
            aria-current="true"
            @click="openOrApply(item);">
            {{$t(item.FilterName)}}
        </button>
        <Delete v-if="item.Mine" :row="item" confirm class="btn btn-link btn-sm ms-auto show-on-hover py-0">
            <i class="bi bi-trash-fill"></i>
        </Delete>
        <Delete v-if="!item.Mine" :title="$t('Remove shared filter')" :row="item" softDelete class="btn btn-link btn-sm ms-auto show-on-hover py-0">
            <i class="bi bi-x-lg"></i>
        </Delete>
       
    </div>


</template>